<template>
    <v-container>
        <ASuccessFour :api="api"/>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
      
        <!-- <ComponentCoursesList/> -->
        <ComponentWebinarList/>
        <!-- <ComponentUserCourseAttendance/> -->
        <!-- <ComponentUserWebinarAttendance/> -->
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
// import ComponentCoursesList from '../../components/courses/ComponentCoursesList.vue';
// import ComponentUserCourseAttendance from '../../components/courses/ComponentUserCourseAttendance.vue';
// import ComponentUserWebinarAttendance from '../../components/courses/ComponentUserWebinarAttendance.vue';
import ComponentWebinarList from '../../components/courses/ComponentWebinarList.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour,
    // ComponentCoursesList,
    // ComponentUserCourseAttendance,
    // ComponentUserWebinarAttendance,
    ComponentWebinarList
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        meetingId:488,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
        data:{
            user:null,
            course:null,
        },
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    //
    },
    methods: {
        fetch(){

        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){

        },
    }
}
</script>